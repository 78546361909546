@import "../../../../globals.scss";

.summary {
    background-color: $bg6;
    padding: 2.4rem 5rem;
    .section_title {
        display: flex;
        color: $purple1;
        align-items: center;
        margin-bottom: 1rem;

        .title_icon {
            margin-right: 1rem;
            font-size: 4rem;
        }
        .title_text {
            font-weight: 700;
            font-size: 2rem;
        }
    }
    .summary_text {
        color: $fg3;
        font-size: 1.3rem;
    }
}
