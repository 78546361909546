@import "../../../../globals.scss";

.top {
    // background-color: $red0;
    padding: 2.4rem 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .picture {
        min-width: 15rem;
        min-height: 15rem;
        width: 15rem;
        height: 15rem;
        overflow: hidden;
        border-radius: 50%;
        border: 4px solid $red1;
        margin-right: 2.4rem;

        #picture {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;

        .inner_container {
            display: flex;
            flex-direction: column;
            width: fit-content;
            .name {
                color: $fg1;
                font-weight: 700;
                font-size: 3rem;

                span:not(span:last-child) {
                    margin-right: 1rem;
                }

                .first_name {
                    color: $fg0;
                }
                .middle_name {
                    color: $fg0;
                }
                .last_name {
                    color: $fg0;
                }
            }

            .job_title {
                display: flex;
                justify-content: flex-end;

                .title {
                    color: $red1;
                    font-size: 1rem;
                    font-weight: 400;
                }
            }
        }

        .contact_info {
            max-width: fit-content;

            .info {
                display: flex;
                flex-direction: row;
                align-items: center;
                .icon {
                    color: $orange0;
                    font-size: 2rem;
                    margin-right: 1rem;
                }
                a {
                    text-decoration: none;
                }
                .info_text {
                    color: $fg0;
                }

                &:not(&:last-child) {
                    margin-bottom: 0.3rem;
                }
            }
        }
    }
}

@media (max-width: 1300px) {
    .top {
        .wrapper {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}

@media (max-width: 880px) {
    .top {
        flex-direction: column;
    }
}
