@import "../../../../globals.scss";

.skill {
    width: 100%;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    position: relative;
    overflow-x: visible;

    .skill_title {
        color: $fg0;
        font-weight: 700;
        font-size: 1.2rem;
    }
    .chart {
        width: 10rem;
        height: 2rem;
        position: absolute;
        right: 2rem;
        background-color: $bg3;
        display: flex;
        border-radius: 20px;
    }
}
