@import "../../../../globals.scss";

.introduction {
    .section_title {
        color: $purple0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 1rem;

        .title_icon {
            font-size: 2.4rem;
            margin-right: 1rem;
        }
        h1 {
            font-size: 2rem;
        }
    }
    .wrapper {
        display: flex;
        height: calc(100vh - (10vh + 6.5rem));
        .left {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 1;
            span {
                font-size: 3rem;
                font-weight: 700;
            }
            .container {
                .first_name {
                    color: $blue1;
                    margin-right: 1rem;
                }
                .middle_name {
                    color: $yellow0;
                }
            }
            .last_name {
                color: $green1;
                text-transform: uppercase;
                margin-bottom: 1vh;
            }
            .seperator {
                height: 0.25vh;
                width: 60%;
                background-color: $orange0;
                margin-bottom: 0.5vh;
            }
            .title {
                display: flex;
                justify-content: flex-end;
                height: 2rem;
                width: 60%;
                color: $red0;
                font-size: 1.5rem;
                font-weight: 400;
            }
        }

        .right {
            flex: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 3rem;
            .introduction_text {
                color: $fg3;
                font-weight: 400;
                font-size: 1.5rem;
            }
        }
    }
}
