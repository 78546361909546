@import "../../../../globals.scss";

.study {
    margin-bottom: 2rem;
    .study_title {
        display: flex;
        align-items: center;
        .bullet_point {
            color: $blue1;
            margin-right: 0.8rem;
            font-size: 2rem;
        }
        .title {
            display: flex;
            flex-direction: column;
            width: fit-content;
            margin-bottom: 1rem;

            h4 {
                color: $yellow1;
                font-size: 1.2rem;
                .institution {
                    color: $green0;
                }
            }
            h5 {
                color: $fg4;
                align-self: flex-end;
                font-size: 0.6rem;
                .location {
                    color: $fg2;
                }
            }
        }
    }
    .description {
        color: $fg0;
        margin-bottom: 1rem;
        margin-left: 2rem;
    }
}
