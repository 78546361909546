@import "../../../../globals.scss";

.work_card {
    margin-bottom: 2rem;
    .work_title {
        display: flex;
        align-items: center;
        .bullet_point {
            color: $aqua1;
            margin-right: 0.8rem;
            font-size: 2rem;
        }
        .title {
            display: flex;
            flex-direction: column;
            width: fit-content;
            margin-bottom: 1rem;

            h4 {
                color: $yellow1;
                font-size: 1.2rem;
            }
            .subinfo {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                h5 {
                    margin-top: 0.2rem;
                    color: $fg1;
                    align-self: flex-end;
                    font-size: 1rem;
                }
            }
        }
    }
    .description {
        color: $fg0;
        margin-bottom: 1rem;
    }
    .experiences {
        color: $fg0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 200px;
        .experience {
            margin-left: 2rem;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            .prompt {
                margin-right: 0.7rem;
                .bullet_point {
                    font-size: 1rem;
                    color: $green0;
                }
            }
            h3 {
                font-weight: 700;
                font-size: 1rem;
            }
        }
    }
}
