@import "../../../../globals.scss";
.skills {
    background-color: $bg1;
    padding-left: 5rem;
    padding-right: 1.2rem;
    padding-top: 3rem;

    .skills_title {
        display: flex;
        color: $orange1;
        align-items: center;
        margin-bottom: 5rem;

        .skills_icon {
            margin-right: 1rem;
            font-size: 4rem;
        }
        .skills_text {
            font-weight: 700;
            font-size: 2rem;
        }
    }
}
