@import "../../../../globals.scss";

.aboutme {
    .section_title {
        color: $orange0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 1rem;

        .title_icon {
            font-size: 2.4rem;
            margin-right: 1rem;
        }
        h1 {
            font-size: 2rem;
        }
    }

    .wrapper {
        display: flex;
        height: calc(100vh - (10vh + 6.5rem));
        .left {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 1;

            .photo_container {
                min-width: 15rem;
                min-height: 15rem;
                width: 15rem;
                height: 15rem;
                overflow: hidden;
                border-radius: 50%;
                border: 4px solid $red1;
                margin-right: 2.4rem;

                #photo {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .right {
            flex: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 3rem;
            .aboutme_text {
                color: $fg3;
                font-weight: 400;
                font-size: 1.5rem;
            }
        }
    }
}
