@import "../../globals.scss";

.contactpage {
    height: 100vh;
    position: relative;
    .sections {
        overflow: hidden;
        margin-top: 1rem;
        height: calc(100vh - (10vh + 2rem));
        position: relative;
        top: calc(10vh + 1rem);
        scroll-behavior: smooth;
        scroll-snap-type: y mandatory;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
        > * {
            height: calc(100vh - (10vh + 3rem));
            background-color: $bg0;
            margin: 1rem;
            margin-top: 0;
            border-radius: 1rem;
            scroll-snap-align: start;
        }
    }
}
