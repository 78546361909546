$bg0: #282828;
$bg1: #1d2021;
$bg2: #3c3836;
$bg3: #504945;
$bg4: #665c54;
$bg5: #7c6f64;
$bg6: #32302f;
$orange0: #d65d0e;
$orange1: #fe8019;
$red0: #cc241d;
$red1: #fb4934;
$green0: #98971a;
$green1: #b8bb26;
$yellow0: #d79921;
$yellow1: #fabd2f;
$blue0: #458588;
$blue1: #83a598;
$purple0: #b16286;
$purple1: #d3869b;
$aqua0: #689d6a;
$aqua1: #8ec97c;
$gray0: #a89984;
$gray1: #928374;
$fg0: #ebdbb2;
$fg1: #fbf1c7;
$fg2: #d5c4a1;
$fg3: #bdae93;
$fg4: #a89984;
