@import "../../../../globals.scss";
@import "react-slideshow-image/dist/styles.css";

.arrow_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    .works_slide_arrow {
        color: $aqua1;
        font-size: 3rem;
        &:hover {
            font-size: 2.6rem;
            color: $aqua0;
        }
    }
    &.prev {
        margin-right: -3rem;
    }
    &.next {
        margin-left: -3rem;
    }
}

.works {
    .section_title {
        color: $aqua0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 1rem;

        .title_icon {
            font-size: 2.4rem;
            margin-right: 1rem;
        }
        h1 {
            font-size: 2rem;
        }
    }

    .wrapper {
        display: flex;
        height: calc(100vh - (10vh + 6.5rem));
        align-items: center;
        justify-content: center;

        .slide {
            width: 80vw;
            border: 2px solid $aqua1;
            border-radius: 1rem;
            .each-slide {
                a {
                    text-decoration: none;
                    div {
                        display: flex;
                        align-items: flex-end;
                        justify-content: center;
                        background-size: cover;
                        height: calc(100vh - (10vh + 6.5rem + 20vh));
                        span {
                            opacity: 0;
                            padding: 20px;
                            font-size: 20px;
                            background: rgba($bg1, 0);
                            width: 80vw;
                            height: 20vh;
                            color: $fg3;
                            text-align: center;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            transition: all 0.5s ease;
                        }
                    }

                    &:hover > div > span {
                        opacity: 1;
                        background: rgba($bg1, 0.8);
                    }
                }
            }
        }
    }
}
