@import "../../../../globals.scss";

.interest {
    margin-left: 2rem;
    .interest_title {
        display: flex;
        align-items: center;
        .bullet_point {
            color: $green0;
            margin-right: 0.8rem;
            font-size: 0.5rem;
        }
        .title {
            display: flex;
            flex-direction: column;
            width: fit-content;


            // margin-bottom: 1rem;

            h4 {
                color: $yellow1;
                font-size: 1.2rem;
                .institution {
                    color: $green0;
                }
            }
        }
    }
}
