@import "../../../../globals.scss";

.contact {
    .section_title {
        color: $yellow1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 1rem;

        .title_icon {
            font-size: 2.4rem;
            margin-right: 1rem;
        }
        h1 {
            font-size: 2rem;
        }
    }
    .container {
        height: calc(100vh - (10vh + 6.5rem));
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        .contact_item {
            display: flex;
            justify-content: center;
            align-items: center;
            .contact_item_icon {
                font-size: 3rem;
                margin-right: 2rem;
                color: $red1;
            }
            h2,
            h2 > a {
                text-decoration: none;
                color: $fg0;
                font-size: 2rem;
            }
        }
    }
}
