@import "../../globals.scss";

.resume {
    background-color: $bg0;
    .wrapper {
        display: flex;
        flex-direction: row;
        .left_panel {
            display: flex;
            flex-direction: column;
            flex: 1;
        }

        .main {
            flex: 3;
            display: flex;
            flex-direction: column;
        }
    }
}
