@import "../../globals.scss";

.menu {
    background-color: $bg3;
    position: absolute;
    top: calc((100vh - (10vh + 1rem)) * -1);
    height: calc(100vh - (10vh + 1rem));
    left: 1rem;
    right: 1rem;
    border-radius: 0 0 1rem 1rem;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;

    ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        li {
            a {
                text-decoration: none;
                color: $fg1;
                font-size: 4rem;
                &:hover {
                    color: $red1;
                }
            }
            margin-bottom: 1rem;
        }
    }
    &.active {
        top: 10vh;
        bottom: 1rem;
        right: 1rem;
        left: 1rem;
    }
}
