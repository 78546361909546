@import "../../globals.scss";

.topbar {
    background-color: $bg3;
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    margin: 1rem 1rem;
    border-radius: 1rem;
    position: fixed;
    width: calc(100vw - 2rem);
    z-index: 5;
    overflow: hidden;

    .left {
        display: flex;
        align-items: center;

        .name {
            span {
                font-family: "Nanum Pen Script";
                font-size: 3rem;
            }
            span:not(span:last-child) {
                margin-right: 0.15rem;
            }
            .first_name {
                color: $blue1;
            }
            .middle_name {
                color: $yellow0;
            }
            .last_name {
                color: $green1;
            }
        }
    }

    .middle {
        display: flex;
        margin-left: -1rem;
        a {
            text-decoration: none;
            color: $fg4;
            font-weight: 400;
            margin-right: 1rem;
            font-size: 2rem;
            &.active {
                color: $fg1;
                font-weight: 700;
            }
        }
        h1 {
            color: $aqua0;
            font-size: 2.4rem;
            display: none;
        }
        &.disabled {
            a {
                display: none;
            }
            h1 {
                display: block;
            }
        }
    }

    .right {
        display: flex;
        align-items: center;

        .menu_icon {
            color: $fg4;
            font-size: 3rem;
            cursor: pointer;

            &:hover {
                color: $fg0;
            }
        }
    }
}
