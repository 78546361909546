@import "../../../../globals.scss";

.languages {
    background-color: $bg0;
    flex: 1;
    padding-left: 5rem;
    padding-right: 1.2rem;
    padding-top: 3rem;

    .languages_title {
        display: flex;
        color: $purple0;
        align-items: center;
        margin-bottom: 5rem;

        .languages_icon {
            margin-right: 1rem;
            font-size: 4rem;
        }
        .languages_text {
            font-weight: 700;
            font-size: 2rem;
        }
    }
}
