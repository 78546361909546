@import "./globals.scss";

body {
    background-color: $bg2;
}

* {
    //! Mulish >> General font
    //! Nanum Pen Script >> Hand writing
    //! Redacted Script >> Gibrish
    font-family: "Mulish", "Nanum Pen Script", "Redacted Script", cursive;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
}
